@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./assets/fonts/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter-SemiBold'), url(./assets/fonts/Inter-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'PPMonumentExtended-Medium';
  src: local('PPMonumentExtended-Medium'), url(./assets/fonts/PPMonumentExtended-Medium.woff) format('woff');
}

@font-face {
  font-family: 'GE_SS_Two-Medium';
  src: local('GE_SS_Two-Medium'), url(./assets/fonts/GE_SS_Two-Medium.woff) format('woff');
}

@font-face {
  font-family: 'GraphikArabic-Regular';
  src: local('GraphikArabic-Regular'), url(./assets/fonts/GraphikArabic-Regular.woff) format('woff');
}
